import { Snackbar, Alert, AlertColor } from '@mui/material';
import React, { FC } from 'react';
import './AppSnackBar.scss';

interface AppSnackBarProps {
  showSnackBar: boolean, 
  setShowSnackbar: React.Dispatch<React.SetStateAction<boolean>>, 
  snackBarSeverity: AlertColor, 
  snackBarMessage: string
}

const AppSnackBar: FC<AppSnackBarProps> = ({showSnackBar,setShowSnackbar, snackBarSeverity,  snackBarMessage}) => (
  <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    open={showSnackBar}
    autoHideDuration={6000}
    onClose={() => setShowSnackbar(false)}
  >
    <Alert
      elevation={6}
      onClose={() => setShowSnackbar(false)}
      severity={snackBarSeverity}
      sx={{ width: "100%" }}
    >
      {snackBarMessage}
    </Alert>
  </Snackbar>
);

export default AppSnackBar;
