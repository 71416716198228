import { ThemeOptions } from "@mui/material/styles/createTheme";

export const themeOptions: ThemeOptions = {
    palette: {
      mode: 'light',
      primary: {
        main: '#3E6204',
        dark: '#00574b',
      },
      secondary: {
        main: '#d81b60',
      },
    },
  };
  