import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { AlertColor } from '@mui/material/Alert/Alert';
import AppSnackBar from '../../components/AppSnackBar/AppSnackBar';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';



let url = "https://vulcanwebcontactstaging.azurewebsites.net/api/ceres-register-user?code=D2hqTcliNAf1zI7Z10oW0lAOY2ifQaE478qLQq7HiK8oWENXykcwng==";
const REGEXP_EMAIL = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export default function Home() {
  const [brochure, setBrochure] = React.useState(true);
  const [meeting, setMeeting] = React.useState<boolean>(false);
  const [demo, setDemo] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [showSnackBar, setShowSnackbar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [snackBarSeverity, setSnackBarSeverity] =
    React.useState<AlertColor>("success");
  let navigate = useNavigate();

  const displaySnackBar = (message: string, severity: AlertColor) => {
    setSnackBarMessage(message);
    setSnackBarSeverity(severity);
    setShowSnackbar(true);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true)
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = {
      email: formData.get('email'),
      user_name: formData.get('name'),
      company: formData.get('company'),
      brochure,
      meeting,
      demo
    }
    console.log(data, data.brochure || data.meeting || data.demo);
    if(data.user_name && data.company && data.email && (data.brochure || data.meeting || data.demo)){
      if(!REGEXP_EMAIL.test(data.email.toString())){
        displaySnackBar("Email Format is incorrect!", "error")
        setIsLoading(false)
      } else{
        axios.post(url, data).then(res => {
          const {message, brochure_url} = res.data 
          displaySnackBar("Thanks for registering your interest. We will be in touch soon.", "success")
          // if (brochure) {
          //   window.open(
          //       brochure_url
          //     );
          // }
  
        }).catch(err => {
          console.log(err)
          displaySnackBar(err?.response?.data?? "", "error")
        }).finally(()=> {
          setIsLoading(false)
        })
      }
      
    } else {
      displaySnackBar("One or more fields are missing, Please fill all fields and try again!", "error")
      setIsLoading(false)


    }

  };

  return (
      <Container component="main" maxWidth="xs"
      style={{ minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',

     }}
      >
        <div style={{ minHeight: '20vh',
            minWidth: "100%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',}}>
        <Container style={{ minHeight: '20vh',
            minWidth: "100%",
            backgroundImage: 'url("backgroundImage.png")',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'}}/>
        <img  className="logo" src={"logo.svg"}/>

        </div>
        
        <CssBaseline />
        <LoadingSpinner open={isLoading} />
        <AppSnackBar showSnackBar={showSnackBar} setShowSnackbar={setShowSnackbar} snackBarSeverity={snackBarSeverity} snackBarMessage={snackBarMessage} />

        <Box
          sx={{
            margin: "auto",
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <Typography component="h1" variant="h5">
            Register to find out more!
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="company"
              label="Conpany Name"
              name="company"
              autoComplete="company"
              autoFocus
            />
            
            <FormGroup>
                <FormControlLabel control={<Checkbox value={brochure} onChange={(event)=>{setBrochure(event.target.checked);}} defaultChecked/>} label="Request for Vulcan Ceres Brochure" />
                <FormControlLabel control={<Checkbox value={meeting} onChange={(event)=>{setMeeting(event.target.checked);}}/>} label="Request a meeting with us" />
                <FormControlLabel control={<Checkbox value={demo} onChange={(event)=>{setDemo(event.target.checked);}} /> } label="Request for a demo" />

            </FormGroup>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Register
            </Button>
            <Grid container>

            </Grid>
          </Box>
        </Box>
      </Container>
  );
}